import {
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import H2 from "./H2";
import { useTranslation } from "react-i18next";

export const FAQ = () => {
  const { t } = useTranslation();
  const faqNumbers = Array.from({ length: 11 }, (_, index) => index + 1);

  return (
    <Stack id="faq" alignItems={"center"}>
      <H2>{t(`faq`)}</H2>

      <Box w={"100%"}>
        <Accordion allowToggle mt={5}>
          {faqNumbers.map((faqNum) => (
            <AccordionItem key={faqNum}>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <Text fontSize={"md"} color={"gray.400"}>
                    {t(`faq${faqNum}Title`)}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text fontSize={"md"}>{t(`faq${faqNum}Description`)}</Text>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Stack>
  );
};
