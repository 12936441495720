import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import { MdTranslate } from "react-icons/md";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Flag from 'react-flagkit';

export const LangSwitcher = () => {
  const { t, i18n: { changeLanguage, language } } = useTranslation();
  const handleChangeLanguage = (lang: string) => {
    changeLanguage(lang);
  }

  // @ts-ignore
  const languages = Object.keys(i18n.options.resources);
  const LangIcon = MdTranslate;
  const iconCode = (lang: string) => lang.toLocaleUpperCase().replace('EN', 'GB');

  return (
    <Menu>
      <MenuButton as={IconButton} icon={<LangIcon />} borderRadius={"50%"}/>
      <MenuList p={3} alignItems={'center'}>
        {languages.map((lang) => (
          <MenuItem
            icon={<Flag country={iconCode(lang)} />}
            key={lang}
            onClick={() => handleChangeLanguage(lang)}
          >
            {t(lang)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
