import {
  Box,
  Button,
  VStack,
  Text,
  HStack,
  Icon,
  Image,
  Stack,
} from "@chakra-ui/react";
import H1 from "./H1";
import H2 from "./H2";
import { useTranslation } from "react-i18next";
import { IoLogoAndroid, IoLogoApple, IoLogoChrome } from "react-icons/io";
import Logo from "../assets/logo.svg";

export const Jumbotron = () => {
  const { t } = useTranslation();

  return (
    <Box id="jumbotron" textAlign={"center"}>
      <VStack gap={20}>
        <VStack gap={2}>
          <H1>
            <HStack>
              <Text>FEE-LESS</Text>
              <Text color={"pink.500"}>GAMING</Text>
            </HStack>
          </H1>

          <Text fontSize={"md"}>KUKU.GAMES {t("jumbotronDescription")}</Text>
        </VStack>

        <Stack direction={{ base: "column", md: "row" }} gap={4}>
          <Button
            href={"https://prediction.kuku.games"}
            px={5}
            py={3}
            as="a"
            target="_blank"
            size="xl"
            colorScheme={"cyan"}
            variant="outline"
            minW={200}
            borderRadius={30}
            fontSize={"lg"}
          >
            {t("playNow")}
          </Button>

          <Button
            href={"#buy"}
            px={5}
            py={3}
            as="a"
            size="xl"
            colorScheme={"yellow"}
            variant="outline"
            minW={200}
            borderRadius={30}
            fontSize={"lg"}
          >
            {t("buy")} $KUKU
          </Button>
        </Stack>
      </VStack>
    </Box>
  );
};
