import { useEffect, useState } from "react";

export const useScrollPosition = () => {
  const [scrollPos, setScrollPos] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 100) {
          setScrollPos(true);
        }else{
          setScrollPos(false);
        }
      });
    }
  }, []);
  return { scrollPos };
};

export const scrollWidthOffset = (el: any) => {
  const navbarHeight = document.getElementById("navbar")?.offsetHeight ?? 0;
  const yCoordinate =
    el.getBoundingClientRect().top + window.scrollY - (navbarHeight + 20);
  window.scrollTo({ top: yCoordinate, behavior: "smooth" });
};