import { Box, Stack, Image, Text, HStack, Icon, VStack } from "@chakra-ui/react";
import H2 from "./H2";
import PredictionImage from "../assets/prediction.png";
import { FiArrowRightCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

export const Prediction = () => {
  const { t } = useTranslation();

  return (
    <Stack
      id="prediction"
      direction={{ base: "column-reverse", lg: "row" }}
      gap={10}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box>
        <Image src={PredictionImage} alt={"Prediction"} />
      </Box>

      <Box flexGrow={1}>
        <Stack maxWidth="lg">
          <H2>{t(`prediction`)}</H2>

          <VStack gap={6} alignItems={"start"}>
            <HStack>
              <Icon as={FiArrowRightCircle} color={'pink.400'} fontSize={"xl"} />
              <Text fontSize={"md"} color={"gray.400"}>
                {t(`predictionLi1`)}
              </Text>
            </HStack>

            <HStack>
              <Icon as={FiArrowRightCircle} color={'pink.400'} fontSize={"xl"} />
              <Text fontSize={"md"} color={"gray.400"}>
                {t(`predictionLi2`)}
              </Text>
            </HStack>

            <HStack>
              <Icon as={FiArrowRightCircle} color={'pink.400'} fontSize={"xl"} />
              <Text fontSize={"md"} color={"gray.400"}>
                {t(`predictionLi3`)}
              </Text>
            </HStack>

            <HStack>
              <Icon as={FiArrowRightCircle} color={'pink.400'} fontSize={"xl"} />
              <Text fontSize={"md"} color={"gray.400"}>
                {t(`predictionLi4`)}
              </Text>
            </HStack>

            <Text fontSize={"md"} color={"gray.400"}>
              {t(`predictionDescription`)}
            </Text>
          </VStack>

        </Stack>
      </Box>

    </Stack>
  );
};
