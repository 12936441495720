import { Box, Text, Stack } from "@chakra-ui/layout";
import { HStack, IconButton } from "@chakra-ui/react";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { LangSwitcher } from "./LangSwitcher";

export const Footer = () => {
  return (
    <Stack>
      <HStack
        alignItems={"center"}
        justifyContent={"space-between"}
        minH={"80px"}
        mx={5}
      >

        <Box>
          <LangSwitcher />
        </Box>

        <Box textAlign={"center"}>
          <Text fontSize={"sm"} fontWeight={"bold"}>
            kuku.games - &copy; 2024
          </Text>
        </Box>

        {/*
        <HStack gap={5}>
          <IconButton
            colorScheme='white'
            variant={"outline"}
            aria-label='Twitter'
            borderRadius={'50%'}
            icon={<FaTwitter />}
            href={"https://twitter.com/kukudotgames"}
            as="a"
            target="_blank"
          />

          <IconButton
            colorScheme='white'
            variant={"outline"}
            aria-label='Telegram'
            borderRadius={'50%'}
            icon={<FaTelegramPlane />}
            href={"https://t.me/kukugames"}
            as="a"
            target="_blank"
          />
        </HStack>
        */}

      </HStack>
    </Stack>
  );
};
