import { Box } from "@chakra-ui/react";
import NavBar from "./NavBar";

export default function Header() {

  return (
    <Box p={4}>
      <NavBar />
    </Box>
  );
}
