import {Image, Stack, Link} from "@chakra-ui/react";
import H2 from "./H2";
import Koinos from "../assets/koinos.png";
import Koincrew from "../assets/koincrew.png";
import Konio from "../assets/konio.png";
import {useTranslation} from "react-i18next";

export const PartnersAndSupporters = () => {
    const {t} = useTranslation();

    return (
        <Stack alignItems={"center"} textAlign={"center"} id="partners" justifyContent={"center"}>
            <H2>{t('partnersSupportersTitle')}</H2>

            <Stack
                spacing={{base: 8, lg: 16}}
                alignItems={"center"}
                direction={{base: "column", lg: "row"}}
            >
                <Link href="https://koinos.io" target="_blank">
                    <Image
                        maxW={250}
                        maxH={250}
                        src={Koinos}
                        alt="Koinos"
                        transition="transform 0.3s ease-out"
                        _hover={{
                            transform: "scale(1.2)",
                        }}
                    />
                </Link>

                <Link href="https://koincrew.com" target="_blank">
                    <Image
                        maxW={150}
                        maxH={150}
                        src={Koincrew}
                        alt="Koincrew"
                        transition="transform 0.3s ease-out"
                        _hover={{
                            transform: "scale(1.2)",
                        }}
                    />
                </Link>

                <Link href="https://konio.io" target="_blank">
                    <Image
                        maxW={250}
                        maxH={250}
                        src={Konio}
                        alt="Konio"
                        transition="transform 0.3s ease-out"
                        _hover={{
                            transform: "scale(1.2)",
                        }}
                    />
                </Link>
            </Stack>
        </Stack>
    );
};
