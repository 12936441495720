import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import cnJSON from './locale/cn.json'
import deJSON from './locale/de.json'
import enJSON from './locale/en.json'
import esJSON from './locale/es.json'
import frJSON from './locale/fr.json'
import inJSON from './locale/in.json'
import itJSON from './locale/it.json'
import jpJSON from './locale/jp.json'
import ptJSON from './locale/pt.json'
import ruJSON from './locale/ru.json'
import saJSON from './locale/sa.json'
import trJSON from './locale/tr.json'

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: {
        cn: {...cnJSON },
        de: {...deJSON },
        en: {...enJSON },
        es: {...esJSON },
        fr: {...frJSON },
        in: {...inJSON },
        it: {...itJSON },
        jp: {...jpJSON },
        pt: {...ptJSON },
        ru: {...ruJSON },
        sa: {...saJSON },
        tr: {...trJSON },
    },
    fallbackLng: "en",
    detection: {
        order: ['querystring', 'navigator'],
        lookupQuerystring: 'lng',
        convertDetectedLanguage: (lng) => lng.split("-")[0]
    }
});
