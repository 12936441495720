import {
  Stack,
  Text,
  Box,
  useToken,
  HStack,
  Icon,
  VStack,
  Button,
} from "@chakra-ui/react";
import H2 from "./H2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import type { ChartData, ChartOptions } from "chart.js";
import { FiArrowRightCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

export default () => {
  const { t } = useTranslation();

  return (
    <Stack
      id="buy"
      direction={{ base: "column", lg: "row" }}
      gap={10}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <VStack minH={400}>
        <H2>{t("buy")} $KUKU</H2>

        <VStack gap={6}>
          <VStack>
            <Text fontSize={"md"}>{t("buyDescriptionLine1")}</Text>
            <Text fontSize={"md"}>{t("buyDescriptionLine2")}</Text>
            <Text fontSize={"md"}>{t("buyDescriptionLine3")}</Text>
          </VStack>

          <Button
            href={"https://tally.so/r/mZNBo5"}
            target="_blank"
            px={5}
            py={3}
            as="a"
            size="xl"
            colorScheme={"yellow"}
            variant="outline"
            minW={200}
            borderRadius={30}
            fontSize={"lg"}
          >
            {t("buyGetInTouch")}
          </Button>
        </VStack>
      </VStack>
    </Stack>
  );
};
