import {
    Text, VStack,
} from "@chakra-ui/react";
import { Wrapper } from "../components/Wrapper";

export const PageNotFound = () => {
    return (
        <Wrapper>
            <VStack>
                <Text fontSize={"md"} color={'gray.400'} textAlign={'center'}>404 NOT FOUND</Text>
            </VStack>
        </Wrapper>
    )
}