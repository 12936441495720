import { Heading } from "@chakra-ui/react"

export default (props: any) => {
    return (
        <Heading
          fontSize={{ base: "4xl", lg: "8xl" }}
          fontWeight={"bold"}
          {...props}
        >
          {props.children}
        </Heading>
    );
}