import { ChakraProvider } from "@chakra-ui/react"
import { Routes, Route } from "react-router-dom";
import theme from "./Theme";
import '@fontsource/comfortaa';
import { PageNotFound } from "./pages/PageNotFound";
import { Home } from "./pages/Home";
import { Maintenance } from "./pages/Maintenance";
import {MAINTENANCE_MODE} from "./Constants";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
    { MAINTENANCE_MODE &&
        <Routes>
          <Route path="*" element={<Maintenance />} />
        </Routes>
    }
    { !MAINTENANCE_MODE &&
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
    }
    </ChakraProvider>
  )

}
