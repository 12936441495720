import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    },
    fonts: {
        heading: `'Comfortaa', sans-serif`,
        body: `'Comfortaa', sans-serif`,
    },
    radii: {
        md: '1em'
    },
    space: {
        '4': '20px',
        '200': '10px'
    },
    colors: {
        gray: {
            '700': '#110d30',
            '800': '#000c20'
        }
    },
    fontSizes: {
        xs: "14px",
        sm: "1rem",
        md: "1.15rem",
        lg: "1.4rem",
        xl: "1.6rem",
        "2xl": "1.7rem",
        "3xl": "2rem",
        "4xl": "2.2rem",
        "5xl": "2.5rem",
        "6xl": "3rem",
        "7xl": "4rem",
        "8xl": "5rem",
      },
});

export default theme;