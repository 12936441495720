import {
  Text,
  Alert,
  AlertDescription,
  Box,
  CloseButton,
  Stack, useDisclosure, Flex,
} from "@chakra-ui/react";
import { Wrapper } from "../components/Wrapper";
import { Jumbotron } from "../components/Jumbotron";
import { Features } from "../components/Features";
import { Prediction } from "../components/Prediction";
import { Token } from "../components/Token";
import { FAQ } from "../components/FAQ";
import { Roadmap } from "../components/Roadmap";
import { PartnersAndSupporters } from "../components/PartnersAndSupporters";
import Buy from "../components/Buy";
import {Community} from "../components/Community";

export const Home = () => {
  return (
    <Wrapper>
      <AlertMessage/>
      <Stack gap={32}>

        <Flex alignItems={"center"} justifyContent={"center"} textAlign={"center"} minH={"100vh"} mb={-32}>
          <Jumbotron/>
        </Flex>

        {/* <StatsBar></StatsBar> */}

        <Features/>
        <Prediction/>
        
        <Token/>

        <Buy></Buy>

        {/* <PredictionSlider></PredictionSlider> */}

        <Roadmap/>
        <FAQ/>
        <PartnersAndSupporters/>
        <Community/>
      </Stack>
    </Wrapper>
  );
};

function AlertMessage() {
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true })

  return isVisible ? (
    <Box position={"fixed"} bottom={0} left={0} width={'100%'} zIndex={2000}>
      <Alert status='success' backgroundColor={"yellow.400"} justifyContent={"center"}>
        <Box>
          <AlertDescription>
            <Text fontSize={{base: "md", md: "lg"}} color={"purple.800"} fontWeight={"bold"}>1 BILLION $KUKU AIRDROP for early players</Text>
          </AlertDescription>
        </Box>
        <CloseButton
          alignSelf='flex-end'
          position='relative'
          right={-1}
          top={-1}
          onClick={onClose}
          color={"purple.800"}
        />
      </Alert>
    </Box>
  ) : (
    <></>
  )
}