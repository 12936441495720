import { VStack, Heading } from "@chakra-ui/react"
import { Wrapper } from "../components/Wrapper"

export const Maintenance = () => {
    return (
        <Wrapper>
            <VStack spacing={6}>
                <Heading size={"lg"} textAlign={'center'}>
                    Maintenance
                </Heading>
            </VStack>
        </Wrapper>
    )
}