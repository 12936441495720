import { Box, Flex } from "@chakra-ui/layout";
import Bg1 from "../assets/bg1.png";
import Header from "./Header";
import { Footer } from "./Footer";

export const Wrapper = (props: { children?: React.ReactNode }) => {
  return (
    <Flex
      direction={"column"}
      backgroundColor={"1b1133"}
      backgroundImage={`url(${Bg1})`}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
      backgroundPosition={"top center"}
      minHeight={"100vh"}
      overflowX={"hidden"}
    >
      <Header />
      <Box mx={"auto"} w={{ base: "90%", lg: 1280 }}>
        {props.children}
      </Box>
      <Footer />
    </Flex>
  );
};
