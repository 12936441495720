import {Image, Stack, Link, IconButton} from "@chakra-ui/react";
import H2 from "./H2";
import Koinos from "../assets/koinos.png";
import Koincrew from "../assets/koincrew.png";
import Konio from "../assets/konio.png";
import {useTranslation} from "react-i18next";
import {FaTelegramPlane, FaTwitter} from "react-icons/fa";

export const Community = () => {
    const {t} = useTranslation();

    return (
        <Stack alignItems={"center"} textAlign={"center"} id="community" minH={300} justifyContent={"center"}>
            <H2>{t('communityTitle')}</H2>

            <Stack
                spacing={{base: 8, lg: 16}}
                alignItems={"center"}
                direction={{base: "column", lg: "row"}}
            >
                <Link href="https://twitter.com/kukudotgames" target="_blank">
                    <FaTwitter size={70}/>
                </Link>

                <Link href="https://t.me/kukugames" target="_blank">
                    <FaTelegramPlane size={70}/>
                </Link>
            </Stack>
        </Stack>
    );
};
