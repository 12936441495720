import { Stack, Box, Text, VStack, Flex } from "@chakra-ui/react";
import React from "react";
import H2 from "./H2";
import H3 from "./H3";
import { useTranslation } from "react-i18next";

const roadmapData: Record<string, any> = {
  2023: {
    Q4: [
      {
        title: "roadmap2023Q4Li1",
        done: true,
      },
      {
        title: "roadmap2023Q4Li2",
        done: true,
      },
      {
        title: "roadmap2023Q4Li3",
        done: true,
      },
      {
        title: "roadmap2023Q4Li4",
        done: true,
      },
    ],
  },
  2024: {
    Q1: [
      {
        title: "roadmap2024Q1Li1",
        done: true,
      },
      {
        title: "roadmap2024Q1Li2",
        done: true,
      },
      {
        title: "roadmap2024Q1Li3",
        done: true,
      },
      {
        title: "roadmap2024Q1Li4",
        done: true,
      },
    ],
    Q2: [
      {
        title: "roadmap2024Q2Li1",
      },
      {
        title: "roadmap2024Q2Li2",
      },
      {
        title: "roadmap2024Q2Li3",
      },
      {
        title: "roadmap2024Q2Li4",
      },
      {
        title: "roadmap2024Q2Li5",
      },
    ],
    Q3: [
      {
        title: "roadmap2024Q3Li1",
      },
      {
        title: "roadmap2024Q3Li2",
      },
      {
        title: "roadmap2024Q3Li3",
      },
      {
        title: "roadmap2024Q3Li4",
      },
    ],
    Q4: [
      {
        title: "roadmap2024Q4Li1",
      },
      {
        title: "roadmap2024Q4Li2",
      },
      {
        title: "roadmap2024Q4Li3",
      },
      {
        title: "roadmap2024Q4Li4",
      },
    ],
  },
};

const Development = (props: { title: string; done: boolean }) => {
  const { t } = useTranslation();

  return (
    <VStack
      w={200}
      p={4}
      borderWidth={1}
      borderRadius={20}
      borderColor={props.done ? "pink.400" : "gray:300"}
      textAlign={"center"}
      justifyContent={"center"}
    >
      <Text fontSize={"sm"} color={"whiteAlpha.800"}>
        {t(props.title)}
      </Text>
    </VStack>
  );
};

export const Roadmap = () => {
  const { t } = useTranslation();

  return (
    <Stack id="roadmap" alignItems={{ base: "start", lg: "center" }} gap={4}>
      <H2>{t(`roadmap`)}</H2>
      {Object.entries(roadmapData).map(([year, roadmaps]) => (
        <React.Fragment key={year}>
          <H3>{year}</H3>
          <Box>
            {Object.keys(roadmaps).map((quarter, index) => (
              <Quarter
                key={quarter}
                id={quarter}
                year={year}
                isRight={(index + parseInt(year)) % 2 === 0}
              />
            ))}
          </Box>
        </React.Fragment>
      ))}
    </Stack>
  );
};

const Quarter = (props: { id: string; year: string; isRight: boolean }) => {
  const { t } = useTranslation();
  const items = roadmapData[props.year][props.id];

  return (
    <VStack alignItems={{ base: "left", lg: "center" }} gap={0}>
      <VStack
        w={{ base: 42, lg: 62 }}
        h={{ base: 42, lg: 62 }}
        borderRadius={"50%"}
        bg={"whiteAlpha.800"}
        justifyContent={"center"}
        gap={0}
      >
        <Text color={"black"} fontWeight={"bold"}>
          {props.id}
        </Text>
      </VStack>

      <Flex
        alignItems={"stretch"}
        gap={0}
        direction={{ lg: props.isRight ? "row" : "row-reverse" }}
      >
        <Box
          w={0.5}
          bg={"whiteAlpha.800"}
          marginLeft={{ base: 4, lg: props.isRight ? 220 : 4 }}
          marginRight={{ base: 4, lg: !props.isRight ? 220 : 4 }}
        ></Box>
        <VStack>
          {items.map((item: any, index: number) => (
            <Box key={index}>
              <Development
                title={t(item.title)}
                done={item.done ? true : false}
              />
            </Box>
          ))}
        </VStack>
      </Flex>
    </VStack>
  );
};
