import {Stack, VStack, HStack, Text, Flex} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import H2 from "./H2";
import H3 from "./H3";
import {MdMoneyOff} from "react-icons/md";
import {IoLogoGameControllerA} from "react-icons/io";
import {IoIosCash} from "react-icons/io";
import {IoIosPhonePortrait} from "react-icons/io";
import {IoIosGitNetwork} from "react-icons/io";
import {IoIosRocket} from "react-icons/io";


export const Features = () => {
    const {t} = useTranslation();

    const items = [
        {
            title: 'feature1Title',
            description: 'feature1Description',
            icon: <IoLogoGameControllerA size={36}/>
        },
        {
            title: 'feature2Title',
            description: 'feature2Description',
            icon: <MdMoneyOff size={36}/>
        },
        {
            title: 'feature3Title',
            description: 'feature3Description',
            icon: <IoIosGitNetwork size={36}/>
        },
        {
            title: 'feature4Title',
            description: 'feature4Description',
            icon: <IoIosCash size={36}/>
        },
        {
            title: 'feature5Title',
            description: 'feature5Description',
            icon: <IoIosPhonePortrait size={36}/>
        },
        {
            title: 'feature6Title',
            description: 'feature6Description',
            icon: <IoIosRocket size={36}/>
        }
    ]

    // Class of style
    const square = {
        bg: "linear-gradient(to left, #7928CA, #FF0080)",
        w: 62,
        h: 62,
        alignItems: "center",
        justifyContent: "center",
        me: 5,
    };

    const title = {
        // w: { base: 170, md: 270, lg: 350 },
        mb: 1.5,
    };

    const description = {
        color: "gray.400",
        fontSize: "md"
    };

    return (
        <Stack id="features">
            <H2 textAlign={"center"}>{t('featuresSlogan')}</H2>

            <Stack gap={8} direction={{base: 'column', lg: 'row'}} flexWrap={'wrap'}>
                {
                    items.map((item, index) =>

                        <HStack key={index} alignItems={"start"} w={{base: '100%', lg: '30%'}}>
                            <Flex sx={{...square}}>
                                {item.icon}
                            </Flex>
                            <VStack alignItems={"start"} gap={1} flexGrow={1} flexBasis={0}>
                                <H3 sx={{...title}}>{t(item.title)}</H3>
                                <Text sx={{...description}}>{t(item.description)}</Text>
                            </VStack>
                        </HStack>
                    )
                }

            </Stack>


        </Stack>
    );
};
