import { Heading } from "@chakra-ui/react"

export default (props: any) => {
    return (
        <Heading
          fontSize={{ base: "2xl", lg: "3xl", xl: "4xl" }}
          fontWeight={"bold"}
          mb={8}
          {...props}
        >
          {props.children}
        </Heading>
    );
}