import { Stack, Text, Box, useToken, HStack, Icon, VStack } from "@chakra-ui/react";
import H2 from "./H2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import type { ChartData, ChartOptions } from 'chart.js';
import { FiArrowRightCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);



export const Token = () => {
  const { t } = useTranslation();
  const [pink, cyan, yellow, green, red, white, gray, font] = useToken(
    'colors',
    ['pink.500', 'cyan.400', 'yellow.500', 'green.200', 'red.200', 'gray.300', 'gray.800', 'fonts.body'],
  );

  //const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  ChartJS.defaults.backgroundColor = 'none';
  ChartJS.defaults.borderColor = 'none';
  ChartJS.defaults.color = white;

  const data: ChartData<'doughnut'> = {
    labels: [
      t(`chartLabel1`) + ' 10%',
      t(`chartLabel2`) + ' 20%',
      t(`chartLabel3`) + ' 20%',
      t(`chartLabel4`) + ' 30%',
      t(`chartLabel5`) + ' 20%',
    ],
    datasets: [{
      label: t(`chartDataLabel`),
      data: [10, 20, 20, 30, 20],
      backgroundColor: gray,
      borderColor: [
        pink,
        cyan,
        yellow,
        red,
        green
      ],
      hoverOffset: 4
    }]
  };

  const options: ChartOptions<'doughnut'> = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          color: white,
          usePointStyle: true,
          font: {
            family: font,
            size: 16
          }
        }
      }
    }
  };

  return (
    <Stack
      id="token"
      direction={{ base: "column", lg: "row" }}
      gap={10}
      alignItems={"center"}
      justifyContent={"center"}
    >

      <Stack flexGrow={1} flexBasis={0}>
        <H2>$KUKU {t(`token`)}</H2>

        <VStack gap={6} alignItems={"start"}>
          <Text fontSize={"md"} color={"gray.400"}>
            {t(`tokenDescriptionLine1`)}
            <br />{t(`tokenDescriptionLine2`)}
          </Text>

          <HStack>
            <Icon as={FiArrowRightCircle} color={'pink.400'} fontSize={"xl"} />
            <Text fontSize={"md"} color={"gray.400"}>
              {t(`tokenLi1`)}
            </Text>
          </HStack>

          <HStack>
            <Icon as={FiArrowRightCircle} color={'pink.400'} fontSize={"xl"} />
            <Text fontSize={"md"} color={"gray.400"}>
              {t(`tokenLi2`)}
            </Text>
          </HStack>

          <HStack>
            <Icon as={FiArrowRightCircle} color={'pink.400'} fontSize={"xl"} />
            <Text fontSize={"md"} color={"gray.400"}>
              {t(`tokenLi3`)}
            </Text>
          </HStack>

          <HStack>
            <Icon as={FiArrowRightCircle} color={'pink.400'} fontSize={"xl"} />
            <Text fontSize={"md"} color={"gray.400"}>
              {t(`tokenLi4`)}
            </Text>
          </HStack>
        </VStack>
      </Stack>


      <Box width={{ base: 350, lg: 500 }} height={{ base: 350, lg: 450 }}>
        <Doughnut data={data} options={options} />
      </Box>

    </Stack>
  );
};
