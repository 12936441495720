import {
  Box,
  Link,
  Text,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  useDisclosure,
  IconButton,
  HStack,
  VStack,
} from "@chakra-ui/react";

import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoMdMenu } from "react-icons/io";
import { scrollWidthOffset, useScrollPosition } from "../lib/utils";
import Logo from "./Logo";


const LINKS: { text: string; url: string }[] = [
  {
    text: "menuItemFeatures",
    url: "#features",
  },
  {
    text: "menuItemPrediction",
    url: "#prediction",
  },
  {
    text: "$KUKU",
    url: "#token",
  },
  {
    text: "menuItemRoadmap",
    url: "#roadmap",
  },
  {
    text: "menuItemFaq",
    url: "#faq",
  },
  {
    text: "menuItemPartners",
    url: "#partners",
  },
];

export default function NavBar() {
  const { scrollPos } = useScrollPosition();

  const hash = window.location.hash.slice(1);
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        scrollWidthOffset(element);
      }
    }
  }, []);

  return (
    <HStack id="navbar" justifyContent={"space-between"} position={"fixed"} backgroundColor={scrollPos ? "blackAlpha.800" : "none"} top={0} left={0} w={"100%"} p={3} zIndex={1399}>
      <Logo/>

      <HStack>
        <Box display={{ base: "none", lg: "block" }}>
          <MenuLinks />
        </Box>
        <Box display={{ base: "block", lg: "none" }}>
          <MenuDrawer />
        </Box>
      </HStack>
    </HStack>
  );
}

const MenuItem = (props: {
  callback: Function;
  to: string;
  children: ReactNode;
}) => {
  const { t } = useTranslation();

  const handleClick = (event: any) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href");
    if (targetId) {
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        scrollWidthOffset(targetElement);
      }
    }
    props.callback();
  };

  return (
    <Link href={props.to} onClick={handleClick}>
      <Text fontSize={"md"}>{t(props.children!.toString())}</Text>
    </Link>
  );
};

const MenuLinks = () => {
  const { t } = useTranslation();

  return (
    <HStack gap={6}>
      {LINKS.map((link, index) => (
        <MenuItem key={index} to={link.url} callback={() => { }}>
          {t(link.text)}
        </MenuItem>
      ))}

      <ButtonPlay />
    </HStack>
  );
};

const ButtonPlay = () => {
  const { t } = useTranslation();

  return (
    <Button
      colorScheme={"cyan"}
      variant="outline"
      as="a"
      target="_blank"
      href="https://prediction.kuku.games"
    >
      {t("playNow")}
    </Button>
  );
};

const MenuDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <IconButton
        aria-label="help"
        style={{ borderRadius: "50%", width: 40, height: 40 }}
        icon={<IoMdMenu />}
        fontSize={30}
        onClick={onOpen}
      />

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        autoFocus={false}
        returnFocusOnClose={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <VStack alignItems={"start"}>
              {LINKS.map((link, index) => (
                <MenuItem key={index} to={link.url} callback={onClose}>
                  {t(link.text)}
                </MenuItem>
              ))}

              <Box mt={4}>
                <ButtonPlay />
              </Box>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
