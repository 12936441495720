import { HStack, Text, Image } from "@chakra-ui/react"
import Logo from "../assets/logo.svg";

export default () => {
    return (
        <HStack gap={0}>
            <Image h={10} src={Logo} cursor={"pointer"} zIndex={2000} mr={2} />
            <Text fontSize={"2xl"} fontWeight={"bold"}>KUKU.</Text>
            <Text fontSize={"2xl"} fontWeight={"bold"} color={"pink.500"}>GAMES</Text>
        </HStack>
    );
}
