import { Heading } from "@chakra-ui/react"

export default (props: any) => {
    return (
        <Heading
          fontSize={{ base: "lg", lg: "xl", xl: "2xl" }}
          fontWeight={"bold"}
          {...props}
        >
          {props.children}
        </Heading>
    );
}